
<template>
    <div id="user-create-tab">
        <div class="row">
            <div class="col-lg-8">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="firstname">First name</label>
                                    <input id="firstname" type="text" class="form-control"
                                    v-model="data.first_name" v-validate="'required|alpha_spaces'" placeholder="First name" name="first_name" />
                                    <span class="text-danger font-sm"  v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="lastname">Last name</label>
                                    <input id="lastname" type="text" class="form-control" v-model="data.last_name"
                                    v-validate="'required|alpha_spaces'" placeholder="Last name" name="last_name" />
                                    <span class="text-danger font-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="useremail">Email address</label>
                                    <input id="useremail" label="Email" v-model="data.email" type="email" v-validate="'required|email'" name="email" placeholder="Enter Email" class="form-control"/>
                                    <span class="text-danger font-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                <label for="user-mobile">Mobile</label>
                                <input id="user-mobile" autocomplete="false" type="text" class="form-control"
                                v-model="data.mobile" v-validate="{required: true, regex: '^\\+?([0-9+]+)$' }" placeholder="mobile" name="mobile" />
                                <span class="text-danger font-sm"  v-show="errors.has('mobile')">{{ errors.first('mobile') }}</span>
                                </div>
                            </div>
                            <!-- end col -->
                            <div class="col-md-6">
                            <div class="form-group">
                            <label class="">Role</label>
                            <select class="custom-select" v-validate="{required: true}" v-model="data.role" name="input-role" placeholder="--select role--">
                                <option disabled value="" key="-1">-- select role--</option>
                                <option :value="role.name" v-for="(role, index) in roleOptions" :key="index">{{role.name | capitalize}}</option>
                            </select>
                            <span class="text-danger invalid-feedback"  v-show="errors.has('input-role')">{{ errors.first('input-role') }}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                            <label class="">Status</label>
                            <select class="custom-select" v-validate="{required: true}" v-model="data.status" name="input-status" placeholder="--select status--">
                                <option disabled value="" key="-1">-- select status --</option>
                                <option :value="status.value" v-for="(status, index) in statusOptions" :key="index">{{status.label}}</option>
                            </select>
                            <span class="text-danger invalid-feedback"  v-show="errors.has('input-status')">{{ errors.first('input-status') }}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="password">New password</label>
                                <input  v-model="data.password" id="password"  ref="password" type="password" class="form-control" v-validate="'required|min:4|max:100'" placeholder="Enter password" name="password" />
                                <span class="text-danger font-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="confirm-password">Confirm password</label>
                                <input v-model="data.password_confirmation" id="confirm-password" type="password" class="form-control" v-validate="'required|min:4|max:100|confirmed:password'" data-vv-as="password" placeholder="Confirm Password" name="confirm_password"  />
                                <span class="text-danger font-sm" v-show="errors.has('confirm_password')">{{ errors.first('confirm_password') }}</span>
                            </div>
                        </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                <b-form-group label="Gender" v-slot="{ ariaDescribedby }">
                                    <b-form-radio-group
                                    v-model="data.gender"
                                    :options="genderOptions"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-gender"
                                    ></b-form-radio-group>
                                </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-end">
                        <div class="col-md-6">
                            <div class="text-right">
                            <button type="button" @click.prevent="createUser()" class="btn btn-success mt-2">
                                <i class="mdi mdi-content-save"></i> Create
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Others', value: 'others' }
      ],
      statusOptions: [
        {label: "Active", value:'active'},
        {label: "Deactivated", value:'deactivated'},
        {label: "Blocked", value:'blocked'}
      ],
      data: {
        first_name: "",
        last_name: "",
        email: "",
        gender: "",
        mobile: null,
        role: "",
        status: "active",
        password: "",
        password_confirmation: "",
      },
    }
  },
  computed: {
    roleOptions() {
      return this.$store.state.userList.roles.map(role => {
        return {
          name: role.name,
          label: role.name
      }})
    },
  },
  methods: {
    createUser() {
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$store.dispatch("userList/addNewUser",formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.resetForm()
          }
         })
      })
    },
    resetForm() {
      this.data = {
        first_name: "",
        last_name: "",
        email: "",
        mobile: null,
        role: "",
        gender: "",
        status: "active",
        password: "",
        password_confirmation: "",
      }
      this.$validator.reset()
    },
  },
  created(){
    this.$store.dispatch("userList/fetchRoles")
  },
}
</script>
